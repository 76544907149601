function Location() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0004 13.4299C13.7235 13.4299 15.1204 12.0331 15.1204 10.3099C15.1204 8.58681 13.7235 7.18994 12.0004 7.18994C10.2772 7.18994 8.88037 8.58681 8.88037 10.3099C8.88037 12.0331 10.2772 13.4299 12.0004 13.4299Z" stroke="#939393" stroke-width="0.8"/>
            <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#939393" stroke-width="0.8"/>
            </svg>
        </>
    )
}

export { Location }