import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import React from 'react';
import { AnimatePresence, } from 'framer-motion'

//Pages
import { Main } from './pages/main';
import { Docs } from './pages/docs';
import { Inf } from './pages/inf';
import { Feedback } from './pages/feedback';

//Components
import { Header } from './components/header';
import { Footer } from './components/footer';

function App() {
  let location = useLocation()

  return (
    <>
      <Header />
      <div className='wrapper'>
        <AnimatePresence mode='sync'>
          <Routes location={location} key={location.pathname}>
            <Route index element={<Main />} />
            <Route path='/inf' element={ <Inf/> } />
            <Route path='/docs' element={ <Docs/> } />
            <Route path='/feedback' element={<Feedback />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </AnimatePresence>
      </div>
      <Footer/>
    </>
  );
}

export default App;
