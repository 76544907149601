import React from "react";
import { NavLink } from "react-router-dom";

class Link extends React.Component {
    render() {
        return (
            <NavLink to={this.props.link} onClick={this.props.eventClick} className={`link ${({ isActive }) => isActive ? this.props.activeStyle : undefined}`}>{this.props.children}</NavLink>
        )
    }
}

export default Link