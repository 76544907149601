import './css/docs.css'
import './css/main.css'
import { Container } from 'react-bootstrap'
import Frame from '../components/frame'

//Images
import { Show } from '../icons/show'

function Docs() {
    return (
        <>
            <Frame>
                <div className='blockMainPage'>
                    <Container>
                        <div className='d-flex flex-column docsBlock'>
                            <div className='d-flex flex-column docs'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p>Обращение</p>
                                    <a href='/approach.pdf' target='blank'><Show/></a>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p>Образец обращения</p>
                                    <a href='/example-approach.pdf' target='blank'><Show/></a>
                                </div>
                            </div>
                            <div className='d-flex flex-column docsTextBlock align-items-center'>
                                <div>Обратите внимание!</div>
                                <p className='col-10'>Для снятия контрольных показаний, с электросчётчика, необходимо предоставить оригинал паспорта на прибор учёта.</p>
                            </div>
                        </div>
                    </Container>
                </div>
            </Frame>
        </>
    )
}

export { Docs }