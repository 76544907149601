import './css/main.css'
import { Container } from 'react-bootstrap'
import Frame from '../components/frame'

//Images
import House from '../assets/house.png'
import { ArrowRight } from '../icons/arrowRight'

function Main() {
    const handleClickScroll = () => {
        const element = document.getElementById('about-us');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Frame>
                <div className="imgMain">
                    <img src={House} alt="" />
                </div>
                <div>
                    <div>
                        <Container>
                            <div className='topBlock align-items-center row col-sm-6'>
                                <div className='d-flex flex-column'>
                                    <div>
                                        <div className="topBlockHeading">
                                            Кстовская Сервисная Компания —
                                        </div>
                                        <div>
                                            предприятие, оказывающее широкий спектр услуг в сфере ЖКХ для управляющих компаний,ТСЖ, а также жителей.
                                        </div>
                                    </div>
                                    <div className="buttonTopBlock d-flex" onClick={handleClickScroll}>
                                        О нас
                                        <ArrowRight/>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <div id='about-us' className='blockMainPage'>
                    <Container>
                        <div className='d-flex flex-column mBlock'>
                            <div className='topBlockHeading text-center'>О нас</div>
                            <p className='textBlock'>
                                С 2017 года «Кстовская Сервисная Компания» выполняет работы по комплексному обслуживанию Внутридомовых Электрических Сетей, а также производит обслуживание Узлов Учёта Тепловой Энергии, Общедомовых Узлов Учёта Холодного Водоснабжения и Индивидуальных Тепловых Пунктов.<br/><br/>
Кстовская Сервисная Компания производит работы по капитальному ремонту инженерных систем и общестроительные работы, не требующие лицензирования.<br/><br/>

Многолетний опыт работы персонала позволяет производить техническое обслуживание жилого фонда максимально эффективно, минимизирует затраты и коммерческие риски управляющих компаний, повышает презентабельность и общий рейтинг управляющей компании перед жителями.
                            </p>
                        </div>
                    </Container>
                </div>
                <div className='blockMainPage'>
                    <Container>
                        <div className='d-flex flex-column mBlock'>
                            <div className='topBlockHeading text-center'>
                                Наши услуги
                            </div>
                            <div className='d-flex flex-column flexBlock'>
                                <div className='d-flex cardsPrice flex-wrap justify-content-center'>
                                    <div className='col d-flex flex-column text-center justify-content-center'>
                                        <div className='serviceName'>Установка вновь с прибором учёта*</div>
                                        <p className='servicePrice'>от 2400 ₽ / шт</p>
                                    </div>
                                    <div className='col d-flex flex-column text-center justify-content-center'>
                                        <div className='serviceName'>Установка вновь с вашим прибором учёта*</div>
                                        <p className='servicePrice'>от 1100 ₽ / шт</p>
                                    </div>
                                    <div className='col d-flex flex-column text-center justify-content-center'>
                                        <div className='serviceName'>Замена с прибором учёта*</div>
                                        <p className='servicePrice'>1750 ₽ / шт</p>
                                    </div>
                                    <div className='col d-flex flex-column text-center justify-content-center'>
                                        <div className='serviceName'>Замена с вашим прибором учёта*</div>
                                        <p className='servicePrice'>800 ₽ / шт</p>
                                    </div>
                                </div>
                                <div className='textFlexBlock text-center'>
                                    * - установка/замена прибора учёта ХВС/ГВС
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                {/*<div className='blockMainPage'>
                    <Container>
                        <div className='d-flex flex-column mBlock'>
                            <div className='topBlockHeading text-center'>
                                Обратная связь
                            </div>
                            <div className='d-flex flex-column formMainBlock col-6 align-self-center'>
                                <div className='formBlock d-flex flex-column'>
                                    <input type="text" placeholder='Ваше Ф.И.О' className="inputMain" />
                                    <input type="text" placeholder='Ваш телефон' className="inputMain" />
                                    <input type="text" placeholder='Ваш адрес эл. почты' className="inputMain" />
                                    <input type="text" placeholder='Тема' className="inputMain" />
                                    <textarea className='textareaMain' placeholder='Сообщение' />
                                </div>
                                <div className='d-flex flex-column formMainButs'>
                                    <div className='formButton align-self-center'>Отправить</div>
                                    <p className='formLText'>Нажимая кнопку "Отправить", я предоставляю персональные данные и соглашаюсь с обработкой моих персональных данных в соответствии с Политикой обработки персональных данных</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>*/}
            </Frame>
            
        </>
    )
}

export { Main }