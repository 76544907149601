import './css/footer.css'
import { Container, Nav } from 'react-bootstrap'
import Link from "./link"
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

//Images
import { Logo } from '../icons/logo'
import { Location } from '../icons/location'
import { Mail } from '../icons/mail'
import { Call } from '../icons/call'
import { Flash } from '../icons/flash'

const tooltip = (
  <Tooltip id="tooltip">
    Возможна связь через мессенджеры
  </Tooltip>
);

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className='footerBlock'>
                <Container>
                    <div className='footerLinksBlock d-flex flex-column'>
                        <div className='footerInfBlock d-flex justify-content-between'>
                            <div className='col-sm-3 logoNameBlock d-flex flex-column'>
                                <Logo />
                                <p className='nameText'>ООО “Кстовская Сервисная Компания”</p>
                            </div>
                            <div className='d-flex col-sm-4 blockInfo flex-column'>
                                <div className='d-flex infTab align-items-center'>
                                    <p className='col'>Россия, Нижегородская область, Кстово, Советский переулок 6, офис 10</p>
                                    <Location/>
                                </div>
                                <div className='d-flex infTab align-items-center'>
                                    <p className='col'>kstovoservicecompany@gmail.com</p>
                                    <Mail/>
                                </div>
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                    <div className='d-flex infTab align-items-center'>
                                        <p className='col'>2-98-96, +7-930-702-98-96</p>
                                        <Call/>
                                    </div>
                                </OverlayTrigger>
                                
                                <div className='d-flex infTab align-items-center'>
                                    <p className='col'>Пн-Чт 8:00-17:00<br/>Пт 8:00-16:00<br/>Обед: 12:00-13:00</p>
                                    <Flash/>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <Nav className="d-flex navLinks justify-content-center footerLinks">
                                <Link link="/" activeStyle="active">Главная</Link>
                                <Link link="/inf" activeStyle="active">Информация</Link>
                                <Link link="/docs" activeStyle="active">Документы</Link>
                                {/*<Link link="/feedback" activeStyle="active">Обратная связь</Link>*/}
                            </Nav>
                        </div>
                    </div>
                    <div className='copyrightBlock row text-center'>
                        <p>2017 - { currentYear } © ООО “Кстовская Сервисная Компания”</p>
                    </div>
                </Container>
            </div>
        </>
    )
}

export { Footer }