function Flash() {
    return (
        <>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.0901 13.78H9.1801V20.98C9.1801 22.66 10.0901 23 11.2001 21.74L18.7701 13.14C19.7001 12.09 19.3101 11.22 17.9001 11.22H14.8101V4.02002C14.8101 2.34002 13.9001 2.00002 12.7901 3.26002L5.2201 11.86C4.3001 12.92 4.6901 13.78 6.0901 13.78Z" stroke="#939393" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </>
    )
}

export { Flash }