import './css/main.css'
import './css/feedback.css'
import Frame from "../components/frame"
import { Container } from "react-bootstrap"

function Feedback() {
    return (
        <>
            <Frame>
                <div className='blockMainPage'>
                    <Container>
                        <script src="https://yastatic.net/q/forms-frontend-ext/_/embed.js"></script>
                        <iframe title="iframe" className="col-sm-7" src="https://forms.yandex.ru/u/5dc6a8cbf198c02335e4754e/?iframe=1" name="ya-form-5dc6a8cbf198c02335e4754e" frameborder="0"></iframe>
                    </Container>
                </div>
            </Frame>
        </>
    )
}

export { Feedback }