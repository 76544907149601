import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap"
import { useState } from "react"
import './css/header.css'
import { Link as Go } from "react-router-dom"
import { Logo } from "../icons/logo"
import { Menu } from "../icons/menu"

import Link from "./link"

function Header() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar sticky="top" expand="lg" className="headBlock justify-content-between">
                <Container>
                    <Go to='/'><Logo /></Go>
                    <div>
                        <Nav className="navLinks linksHeader">
                            <Link link="/" activeStyle="active">Главная</Link>
                            <Link link="/inf" activeStyle="active">Информация</Link>
                            <Link link="/docs" activeStyle="active">Документы</Link>
                            {/*<Link link="/feedback" activeStyle="active">Обратная связь</Link>*/}
                        </Nav>
                        <div className="navToggler" onClick={handleShow}>
                            <Menu />
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="linksOffcanvas flex-column">
                        <Link link="/" activeStyle="active" eventClick={handleClose}>Главная</Link>
                        <Link link="/inf" activeStyle="active" eventClick={handleClose}>Информация</Link>
                        <Link link="/docs" activeStyle="active" eventClick={handleClose}>Документы</Link>
                        {/*<Link link="/feedback" activeStyle="active" eventClick={handleClose}>Обратная связь</Link>*/}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export { Header }