import React from "react"
import { motion } from "framer-motion"

class Frame extends React.Component {
    render() {
        return (
            <motion.div className={`${this.props.cont}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1.6, ease: 'easeInOut' } }}
                exit={{ opacity: 0, transition: { duration: 0.4, ease: 'linear' } }}
            >
                { this.props.children }
            </motion.div>
        )
    }
    
}

export default Frame