function Logo() {
    return (
        <>
            <svg width="100" height="40" viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.93629 31.2285C8.93079 31.8688 8.31764 32.3175 7.72491 32.115C7.1144 31.9064 6.89588 31.1366 7.30197 30.6249L21.8143 12.3402C22.094 11.9878 22.5136 11.7833 22.9567 11.7833H28.6476C29.9088 11.7833 30.5864 13.3022 29.7614 14.2798L8.4962 39.4781C8.21648 39.8095 7.81 40 7.3824 40H1.4726C0.655185 40 -0.00581388 39.3177 3.8565e-05 38.48L0.258394 1.49843C0.264187 0.669141 0.921776 0 1.73096 0H7.71897C8.53733 0 9.19872 0.683836 9.19151 1.52253L8.93629 31.2285ZM15.1112 27.3666C14.7255 26.6982 14.8976 25.839 15.509 25.3804L19.8196 22.1471C20.5097 21.6294 21.4834 21.8211 21.9373 22.5639L31.1809 37.6897C31.7952 38.6948 31.0901 40 29.9329 40H23.2412C22.7206 40 22.2386 39.7183 21.9736 39.259L15.1112 27.3666Z" fill="#4FB5EB"/>
                <path d="M65.9807 30.1489C65.9807 32.261 65.3689 34.0596 64.1453 35.5447C62.9538 36.9968 61.1989 38.1024 58.8804 38.8614C56.562 39.6205 53.7444 40 50.4277 40C47.0466 40 44.0841 39.571 41.5403 38.7129C38.9964 37.8549 37.0161 36.6503 35.5992 35.0992C34.5559 33.957 33.8705 32.6806 33.5431 31.2699C33.3484 30.4312 34.0334 29.7034 34.8746 29.7034H41.297C41.9779 29.7034 42.5457 30.2024 42.8997 30.7986C43.1306 31.1875 43.4342 31.5485 43.8104 31.8815C44.5511 32.5085 45.501 32.9871 46.6602 33.3171C47.8194 33.6141 49.188 33.7626 50.7658 33.7626C52.8911 33.7626 54.5011 33.5316 55.5959 33.0696C56.6908 32.6075 57.2382 31.9145 57.2382 30.9905C57.2382 30.2974 56.8357 29.7694 56.0307 29.4064C55.2578 29.0433 53.8571 28.7793 51.8284 28.6143L46.8051 28.2183C43.6494 27.9873 41.1378 27.4757 39.2701 26.6837C37.4025 25.8916 36.0661 24.8851 35.2611 23.664C34.4883 22.4429 34.1019 21.0733 34.1019 19.5553C34.1019 17.4761 34.7298 15.7435 35.9856 14.3574C37.2737 12.9714 39.0447 11.9318 41.2988 11.2388C43.5528 10.5457 46.1933 10.1992 49.2202 10.1992C52.2471 10.1992 54.9358 10.6117 57.2865 11.4368C59.6694 12.2618 61.5692 13.4004 62.986 14.8525C64.0143 15.8829 64.7264 17.038 65.1223 18.3177C65.3859 19.1698 64.6917 19.9513 63.8195 19.9513H57.5277C56.8257 19.9513 56.2427 19.4167 55.8484 18.8215C55.6881 18.5796 55.4913 18.3456 55.2578 18.1197C54.7104 17.5586 53.9054 17.1131 52.8428 16.7831C51.8124 16.4201 50.4599 16.2386 48.7855 16.2386C46.8856 16.2386 45.4366 16.4531 44.4384 16.8821C43.4401 17.3111 42.941 17.9382 42.941 18.7632C42.941 19.3902 43.2469 19.8853 43.8587 20.2483C44.4706 20.6113 45.6298 20.8753 47.3364 21.0403L53.8571 21.5354C56.9484 21.7994 59.3634 22.3109 61.1023 23.07C62.8733 23.796 64.1292 24.7531 64.8698 25.9411C65.6104 27.1292 65.9807 28.5318 65.9807 30.1489Z" fill="#4FB5EB"/>
                <path d="M98.6001 27.4757C99.4752 27.4757 100.166 28.2573 99.9599 29.129C99.5339 30.9326 98.725 32.5597 97.5332 34.0101C96.0198 35.8912 94.0555 37.3598 91.6405 38.4159C89.2576 39.472 86.5688 40 83.5742 40C80.2575 40 77.3272 39.373 74.7833 38.1189C72.2395 36.8648 70.2591 35.1157 68.8423 32.8716C67.4254 30.6274 66.717 28.0368 66.717 25.0996C66.717 22.1624 67.4254 19.5718 68.8423 17.3276C70.2591 15.0835 72.2395 13.3344 74.7833 12.0803C77.3272 10.8262 80.2575 10.1992 83.5742 10.1992C86.5688 10.1992 89.2576 10.7272 91.6405 11.7833C94.0555 12.8393 96.0198 14.3079 97.5332 16.1891C98.7292 17.619 99.5395 19.2466 99.9643 21.0717C100.167 21.9433 99.4766 22.7234 98.6023 22.7234H92.5096C91.8076 22.7234 91.2214 22.2072 90.972 21.5347C90.5109 20.2911 89.7355 19.3013 88.6458 18.5652C87.2612 17.6081 85.5706 17.1296 83.5742 17.1296C81.9963 17.1296 80.6117 17.4431 79.4202 18.0702C78.261 18.6972 77.3594 19.6048 76.7154 20.7928C76.0714 21.9809 75.7493 23.4165 75.7493 25.0996C75.7493 26.7827 76.0714 28.2183 76.7154 29.4064C77.3594 30.5944 78.261 31.502 79.4202 32.129C80.6117 32.7561 81.9963 33.0696 83.5742 33.0696C85.6028 33.0696 87.3095 32.5745 88.6941 31.5845C89.7479 30.8058 90.5033 29.8265 90.9603 28.6464C91.2169 27.9835 91.8002 27.4757 92.496 27.4757H98.6001Z" fill="#4FB5EB"/>
            </svg>
        </>
    )
}

export { Logo }